import Highway from '@dogstudio/highway';
import {
  createTransitionElements,
  setCustomScrollbar,
  setSparkies,
  setTabs,
  animateCss,
  setMailSender,
  initModalTriggers,
  initApplicationForm,
  clearPageModifiers
} from './utils';
import Quicklink, { listen, prefetch} from 'quicklink/dist/quicklink.mjs';
import 'intersection-observer';

class Renderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    // prefetch all links
    listen({
      el: document
    });
    
    // clear all page modifiers from previous transition
    clearPageModifiers();

    // set document title
    const slug = this.properties.view.dataset.slug;
    const title = this.properties.view.dataset.title
    if (document.querySelector(`[data-slug=${slug}]`)) {
      document.querySelector(`[data-slug=${slug}]`).classList.add('active');
    }
    document.title = `${title} - EC Calderon`;

    // add transition overlay element when page loads
    createTransitionElements();
  }
  onEnterCompleted() {
    const container = this.wrap.querySelector('.contentWrapper');
    // this.wrap.querySelector('.content').style.removeProperty('transform');
    // this.wrap.querySelector('.content').style.removeProperty('transform-origin');
    // this.wrap.querySelector('.content').style.removeProperty('opacity');
    // this.wrap.querySelector('.content').style.transformOrigin = 'center center';
    // this.wrap.querySelector('.content').style.transform = 'scale(1)';
    // this.wrap.querySelector('.content').style.opacity = '1';

    // set the custom scrollbar on initial page load
    setCustomScrollbar(container);

    // lottie sparkles
    setSparkies(container);

    // tabs
    setTabs(container);

    // animations
    animateCss();
    
    setTimeout(() => container.scrollTo(0, 0), 300);

    // contact mailer
    setMailSender(container);

    // modal triggers
    initModalTriggers(container);

    // application form
    initApplicationForm(container);

    // section bg
    // const sections = document.querySelectorAll('.section');
    // sections.forEach(section => {
    //   const sectionBg = section.querySelector('.sectionBg');
    //   if (sectionBg) {
    //     const imgSrc = sectionBg.querySelector('> img').getAttribute('src');
    //     sectionB
    //   }
    // });
    
  }
  onLeave() {
    // clear all page modifiers from previous transition
    clearPageModifiers();

    // clear the active nav links
    [...document.querySelectorAll('.navLink')].forEach(nl => {
      nl.classList.remove('active');
    });

    // prevent element animation from triggering again when exiting
    [...document.querySelectorAll('*[class*=animate]')].forEach(el => {
      [...el.classList].forEach(cl => {
        if (cl.indexOf('animate') > -1) {
          el.classList.remove(cl);
        }
      });
    });
  }
  // onLeaveCompleted() {}
}

export default Renderer;