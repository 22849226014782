import inView from 'in-view';
import Mailgun from 'mailgun-js';
const path = require('path');

export const createTransitionElements = (to) => {
  const parentEl = (typeof to !== 'undefined') ? to : document.querySelector('.content');
  if (!parentEl.querySelector('.transitionOverlay')) {
    const trEl = document.createElement('div');
    const trElSpin = document.createElement('div');
    const trElCover = document.createElement('div');
    const trLottie = document.createElement('div')
    trEl.classList.add('transitionOverlay');
    trElSpin.classList.add('transitionOverlay__spin');
    trElCover.classList.add('transitionOverlay__cover');
    trLottie.classList.add('transitionOverlay__lottie');
    trLottie.innerHTML = `<lottie-player src="https://assets5.lottiefiles.com/packages/lf20_pJ1bS2.json" background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay></lottie-player>`;
    trEl.appendChild(trElSpin);
    trEl.appendChild(trElCover);
    trElSpin.appendChild(trLottie);
    parentEl.appendChild(trEl);
  }
};

export const setCustomScrollbar = (el) => {
  // const ps = new PerfectScrollbar(el, {
  //   handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
  //   wheelSpeed: 2,
  //   wheelPropagation: true,
  //   minScrollbarLength: 100,
  //   suppressScrollX : true
  // });

  // main nav transition when scrolling
  const mainNav = document.querySelector('.mainNav');
  const logoWhite = document.querySelector('.branding img.light');
  const logoDark = document.querySelector('.branding img.dark');
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  const styles = window.getComputedStyle(mainNav);
  const nHUnit = 'px';
  const nH = styles.getPropertyValue('max-height').split(nHUnit)[0];
  el.addEventListener('scroll', (e) => {
    if (e.target.scrollHeight > (vh * 1.4)) {
      const perc = Math.floor(e.target.scrollTop / (vh / 3) * 100);
      if (perc <= 100) {
        const navHeight = `${Math.floor(nH - ((nH / 4) * perc / 100))}px`;
        mainNav.style.cssText = `max-height: ${navHeight}; background-color: rgba(255, 255, 255, ${(perc * 0.9) / 100})`;
        logoWhite.style.cssText =`opacity: ${1 - perc/100};`;
        logoDark.style.cssText =`opacity: ${perc/100};`;
        document.body.classList.remove('page-scrolled');
      } else {
        document.body.classList.add('page-scrolled');
        mainNav.style.cssText = `background-color: rgba(255, 255, 255, 0.9)`;
      }
    }
  });
};

export const animateCss = () => {
  inView('.animated').on('enter', (e) => {
    e.classList.remove('animated');
    if (e.dataset.animation) {
      const animclass = `animate__${e.dataset.animation}`;
      e.classList.add(animclass);
    }
    if (e.dataset.animationDelay) {
      const animclassdelay = `animate__delay-${e.dataset.animationDelay}ms`;
      e.classList.add(animclassdelay);
    }
    if (e.dataset.animationDuration) {
      const animeclassduration = `animate__duration-${e.dataset.animationDuration}ms`;
      e.classList.add(animeclassduration);
    }
  });
};

export const setSparkies = (container) => {
  const sparkies = container.querySelectorAll('.sparky');
  const handleSparkyHover = (ev) => {
    ev.currentTarget.classList.add('hover');
    const player = ev.currentTarget.querySelector('lottie-player');
    player.seek(0);
    player.play();
  }
  const handleSparkyLeave = (ev) => {
    ev.currentTarget.classList.remove('hover');
    const player = ev.currentTarget.querySelector('lottie-player');
    player.stop();
  }
  sparkies.forEach(sparky => {
    sparky.addEventListener('mouseenter', (ev) => handleSparkyHover(ev));
    sparky.addEventListener('focus', (ev) => handleSparkyHover(ev));
    sparky.addEventListener('mouseleave', (ev) => handleSparkyLeave(ev));
    sparky.addEventListener('blur', (ev) => handleSparkyLeave(ev));
  });
};

export const setTabs = (container = document) => {
  const handleItemClick = (ev, cntx, tab) => {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    const target = ev.currentTarget.getAttribute('href').split('#')[1];
    cntx.querySelectorAll(`.tabPage`).forEach(tabPage => {
      if (tabPage.getAttribute('data-tab-id') === target) {
        tabPage.classList.add('active');
      } else {
        tabPage.classList.remove('active');
      }
    });
    tab.querySelectorAll('.tabItem').forEach(item => {
      const link = item.querySelector('.tabLink').getAttribute('href').split('#')[1];
      if (link === target) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  }
  const tabs = container.querySelectorAll('.tab')
  tabs.forEach(tab => {
    if (tab.getAttribute('listener') !== 'true') {
      tab.setAttribute('listener', 'true');
      const cntx = container.querySelector(tab.getAttribute('data-target'));
      const tabItems = tab.querySelectorAll('.tabItem');
      tabItems.forEach(item => {
        const link = item.querySelector('.tabLink');
        if (link.getAttribute('listener') !== 'true') {
          link.setAttribute('listener', 'true');
          link.addEventListener('click', (ev) => {
            handleItemClick(ev, cntx, tab);
          });
        }
      });
    }
  });
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhoneNumber = (input_str) => {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(input_str);
}

export const clearPageModifiers = () => {
  // document.body.classList.remove('page-scrolled', 'menu-open');
  document.body.setAttribute('class', '');
  const mainNav = document.querySelector('.mainNav');
  const logoWhite = document.querySelector('.branding img.light');
  const logoDark = document.querySelector('.branding img.dark');
  mainNav.style.cssText = '';
  logoWhite.style.cssText = `opacity: 1;`;
  logoDark.style.cssText = `opacity: 0;`;
};

export const setMailSender = (container) => {
    
  // SEND CONTACT FORM
  const sendMailButton = container.querySelector('.sendMail')
  if (sendMailButton && sendMailButton.getAttribute('listener') !== 'true') {
    sendMailButton.setAttribute('listener', 'true')
    const form = container.querySelector('.sendMailForm')
    const nameField = form.querySelector('input[name="name"]');
    const companyField = form.querySelector('input[name="company"]');
    const emailField = form.querySelector('input[name="email"]');
    const phoneField = form.querySelector('input[name="phone"]');
    const messageField = form.querySelector('textarea[name="message"]');

    // validations
    nameField.addEventListener('keyup', (ev) => {
      if (ev.currentTarget.value.length >= 3) {
        ev.currentTarget.parentNode.classList.remove('error');
      } else {
        ev.currentTarget.parentNode.classList.add('error');
      }
    });
    nameField.addEventListener('blur', (ev) => {
      if (ev.currentTarget.value.length < 3) {
        ev.currentTarget.parentNode.classList.add('error');
      }
    });

    emailField.addEventListener('keyup', (ev) => {
      if (ev.currentTarget.value.length >= 3) {
        ev.currentTarget.parentNode.classList.remove('error');
      } else {
        ev.currentTarget.parentNode.classList.add('error');
      }
      if (validateEmail(ev.currentTarget.value)) {
        ev.currentTarget.parentNode.classList.remove('error');
      } else {
        ev.currentTarget.parentNode.classList.add('error');
      }
    });
    emailField.addEventListener('blur', (ev) => {
      if (ev.currentTarget.value.length >= 3) {
        ev.currentTarget.parentNode.classList.remove('error');
      } else {
        ev.currentTarget.parentNode.classList.add('error');
      }
      if (validateEmail(ev.currentTarget.value)) {
        ev.currentTarget.parentNode.classList.remove('error');
      } else {
        ev.currentTarget.parentNode.classList.add('error');
      }
    });

    messageField.addEventListener('keyup', (ev) => {
      if (ev.currentTarget.value.length >= 15) {
        ev.currentTarget.parentNode.classList.remove('error');
      } else {
        ev.currentTarget.parentNode.classList.add('error');
      }
    });
    messageField.addEventListener('blur', (ev) => {
      if (ev.currentTarget.value.length >= 15) {
        ev.currentTarget.parentNode.classList.remove('error');
      } else {
        ev.currentTarget.parentNode.classList.add('error');
      }
    });

    sendMailButton.addEventListener('click', (ev) => {
      ev.preventDefault();
      // console.log('send mail button clicked...');
      let errorCount = 0;
      if (nameField.value === '') {
        errorCount++;
        nameField.parentNode.classList.add('error');
      } else if (nameField.value.length < 3) {
        errorCount++;
        nameField.parentNode.classList.add('error');
      }

      if (emailField.value === '') {
        errorCount++;
        emailField.parentNode.classList.add('error');
      } else if (emailField.value.length < 6) {
        errorCount++;
        emailField.parentNode.classList.add('error');
      } else if (!validateEmail(emailField.value)) {
        errorCount++;
        emailField.parentNode.classList.add('error');
      }

      if (messageField.value === '') {
        errorCount++;
        messageField.parentNode.classList.add('error');
      } else if (messageField.value.length < 15) {
        errorCount++;
        messageField.parentNode.classList.add('error');
      }

      if (errorCount === 0) {
        console.log('sending mail...');
        form.classList.add('processing');
        sendMail();
      }
    });

    const sendMail = () => {
      const req = {
        body: {
          email: emailField.value,
          name: nameField.value,
          company: companyField.value ? companyField.value : 'none',
          phone: phoneField.value ? phoneField.value : 'none',
          message: messageField.value
        }
      };
      //We pass the api_key and domain to the wrapper, or it won't be able to identify + send emails
      var mailgun = new Mailgun({ apiKey: process.env.MAILGUN_KEY, domain: process.env.MAILGUN_DOMAIN });
      var d = new Date();
      var year = d.getFullYear();

      var recieverData = {
        //Specify email data
        from: `${req.body.name} <${req.body.email}>`,
        //The email to contact
        to: `${process.env.EMAIL_ADDRESS}`,
        //Subject and text data  
        subject: `Web Contact from ${req.body.name}`,
        html: `
          <div style='background: #f3f3f3; width: 100%; max-width: 600px; border-radius: 20px; overflow: hidden; margin: auto; font-family: Gill Sans Extrabold, sans-serif, Arial;'>
            <div style='text-align: center; padding: 20px; background: #fdc500;'>
              <img src='http://${process.env.EMAIL_DOMAIN}/email-assets/logo.png' style='width: 240px; height: auto;'>
            </div>
            <div style='text-align: center; padding: 20px; background: #051833;'>
              <h2 style='font-weight: bold; color: #ffffff; margin: 0;'>EC Calderon Construction Development, Inc.</h2>
            </div>
            <div style='padding: 20px;'>
              <h1 style='margin: 0 0 20px 0;'>Message from <span style='color: #D90429;'>${req.body.name}</span></h1>
              <div style='background: #ffffff; padding: 20px;'>
                <h4 style='margin: 0 0 10px 0;'>Name: ${req.body.name}</h4>
                <h4 style='margin: 0 0 10px 0;'>Email Address: ${req.body.email}</h4>
                <h4 style='margin: 0 0 10px 0;'>Company Name: ${req.body.company}</h4>
                <h4 style='margin: 0 0 10px 0;'>Phone Number: ${req.body.phone}</h4>
                <div style='width: 100%; height: 1px; background: #ccc; margin: 20px 0;'></div>
                <p style='margin: 0 0 10px 0; width:100%; white-space: pre-wrap; text-overflow: visible; word-wrap: break-word; line-height: 1.5;'>${req.body.message}</p>
              </div>
              <h3 style='margin: 10px 0 0 0; text-align: center;'>Note: You can reply directly to ${req.body.name}'s message.</h3>
            </div>
            <div style='width: 100%; height:1px; background-color:#d3d3d3; margin: 20px 0'></div>
            <div style='padding: 20px;'>
              <div style='color: #636363; margin-bottom: 20px;'><small>The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future. If you have any further questions, please tell us without hesitation: eccdi@eccalderon.com</small></div>
              <div style='margin-bottom: 20px; color: #636363;'><small>Copyright &copy; ${year} EC Calderon Construction Development, Inc. All rights reserved.</small></div>
              <table celpadding='10px' width='100%' border='0'>
                <tr>
                  <td width='50%' valign='top'>
                    <div style='color: #636363;'>
                      <small>
                        <strong>EC Calderon Construction Development, Inc.</strong><br>
                        <strong>Main Office</strong><br>
                        P. Suico Tingub,<br>
                        Pagsabungan Road, Mandaue City,<br>
                        Cebu, Philippines 6000<br><br>
                        Tel +63 32 328 0912<br>
                        Fax +63 32 422 1055<br>
                      </small>
                    </div>
                  </td>
                  <td width='50%' valign='top'>
                    <div style='color: #636363; margin-bottom: 10px;'>
                      <div><small><strong>Connect with us</strong></small></div>
                      <a href='#facebook' style='margin: 0 5px;'><img src='http://${process.env.EMAIL_DOMAIN}/email-assets/icon_facebook.png'></a>
                      <a href='#twitter' style='margin: 0 5px;'><img src='http://${process.env.EMAIL_DOMAIN}/email-assets/icon_twitter.png'></a>
                      <a href='#linkedin' style='margin: 0 5px;'><img src='http://${process.env.EMAIL_DOMAIN}/email-assets/icon_linkedin.png'></a>
                      <a href='#instagram' style='margin: 0 5px;'><img src='http://${process.env.EMAIL_DOMAIN}/email-assets/icon_instagram.png'></a>
                    </div>
                    <a href='http://www.eccalderon.com' style='text-align: left;'>www.eccalderon.com</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        `
      };

      //Invokes the method to send emails given the above data with the helper library
      mailgun.messages().send(recieverData, function (err, body) {
        //If there is an error, render the error page
        if (err) {
          // res.status(500).send(err)
          console.log("got an error: ", err);
          form.classList.remove('processing');
        }
        //Else we can greet    and leave
        else {
          //Here "submitted.jade" is the view file for this landing page 
          //We pass the variable "email" from the url parameter in an object rendered by Jade
          // return res.status(200).send({ success: true })
          // console.log(body);
          // mailgun.messages().send(senderData, function (err, body) {
          //   if (err) {
          //     // res.status(500).send(err)
          //     console.log("got an error: ", err);
          //   }
          // });
          console.log("email sent...");
          form.classList.remove('processing');
          form.classList.add('success');
        }
      });
    };
  };
};

export const initModals = () => {
  if (document.querySelectorAll('.modal').length > 0) {
    const closeModal = (cntx) => {
      cntx.classList.remove('show');
      document.body.classList.remove('no-scroll')
    }
    document.querySelectorAll('.modal').forEach(modal => {
      modal.querySelector('.modalHeader .close').addEventListener('click', (ev) => {
        ev.preventDefault();
        ev.stopImmediatePropagation();
        closeModal(modal);
      });
    });
  }
};

export const initModalTriggers = (container) => {
  if (container.querySelectorAll('[data-target=modal]').length > 0) {
    container.querySelectorAll('[data-target=modal]').forEach(btn => {
      btn.addEventListener('click', (ev) => {
        ev.preventDefault();
        document.body.classList.add('no-scroll')
        const target = document.querySelector(`#${ev.currentTarget.getAttribute('data-target-id')}`);
        target.classList.add('show');
      });
    });
  }
};

export const initApplicationForm = (container) => {
  if (container.querySelectorAll('[data-target-id=modal-applicationform]').length > 0) {
    container.querySelectorAll('[data-target-id=modal-applicationform]').forEach(btn => {
      btn.addEventListener('click', (ev) => {
        ev.preventDefault();
        ev.stopImmediatePropagation();
        const target = document.querySelector(`#${ev.currentTarget.getAttribute('data-target-id')}`);
        const title = ev.currentTarget.getAttribute('data-title');
        const formInputs = target.querySelectorAll('.sendApplicationForm .form-input');
        const form = target.querySelector('.sendApplicationForm');
        form.classList.remove('success');
        formInputs.forEach(formInput => {
          formInput.querySelector('input, textarea').value = '';
          formInput.classList.remove('error');
        });
        target.querySelector('.applicationTitle').innerHTML = title;
        target.querySelector('input[name=position]').value = title;
    
        const sendButton = target.querySelector('.sendButton')
        if (sendButton && sendButton.getAttribute('listener') !== 'true') {
          sendButton.setAttribute('listener', 'true')
          const positionField = form.querySelector('input[name="position"]');
          const nameField = form.querySelector('input[name="name"]');
          const emailField = form.querySelector('input[name="email"]');
          const phoneField = form.querySelector('input[name="phone"]');
          const messageField = form.querySelector('textarea[name="message"]');
          const resumeField = form.querySelector('input[name="resume"]');

          // validations
          nameField.addEventListener('keyup', (ev) => {
            if (ev.currentTarget.value.length >= 3) {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });
          nameField.addEventListener('blur', (ev) => {
            if (ev.currentTarget.value.length < 3) {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });
      
          emailField.addEventListener('keyup', (ev) => {
            if (ev.currentTarget.value.length >= 3) {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
            if (validateEmail(ev.currentTarget.value)) {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });
          emailField.addEventListener('blur', (ev) => {
            if (ev.currentTarget.value.length >= 3) {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
            if (validateEmail(ev.currentTarget.value)) {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });
          
          phoneField.addEventListener('keyup', (ev) => {
            if (ev.currentTarget.value.length === 11) {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
            if (validatePhoneNumber(ev.currentTarget.value)) {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });
          phoneField.addEventListener('blur', (ev) => {
            if (ev.currentTarget.value.length < 8) {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });
      
          messageField.addEventListener('keyup', (ev) => {
            if (ev.currentTarget.value.length >= 15) {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });
          messageField.addEventListener('blur', (ev) => {
            if (ev.currentTarget.value.length >= 15) {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });
      
          resumeField.addEventListener('keyup', (ev) => {
            if (ev.currentTarget.value != '') {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });
          resumeField.addEventListener('blur', (ev) => {
            if (ev.currentTarget.value != '') {
              ev.currentTarget.parentNode.classList.remove('error');
            } else {
              ev.currentTarget.parentNode.classList.add('error');
            }
          });

          sendButton.addEventListener('click', (ev) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            console.log('send mail button clicked...');
            let errorCount = 0;
            if (nameField.value === '') {
              errorCount++;
              nameField.parentNode.classList.add('error');
            } else if (nameField.value.length < 3) {
              errorCount++;
              nameField.parentNode.classList.add('error');
            }
      
            if (emailField.value === '') {
              errorCount++;
              emailField.parentNode.classList.add('error');
            } else if (emailField.value.length < 6) {
              errorCount++;
              emailField.parentNode.classList.add('error');
            } else if (!validateEmail(emailField.value)) {
              errorCount++;
              emailField.parentNode.classList.add('error');
            }
      
            if (phoneField.value === '') {
              errorCount++;
              phoneField.parentNode.classList.add('error');
            } else if (phoneField.value.length < 8) {
              errorCount++;
              phoneField.parentNode.classList.add('error');
            } else if (!validatePhoneNumber(phoneField.value)) {
              errorCount++;
              phoneField.parentNode.classList.add('error');
            }
      
            if (messageField.value === '') {
              errorCount++;
              messageField.parentNode.classList.add('error');
            } else if (messageField.value.length < 15) {
              errorCount++;
              messageField.parentNode.classList.add('error');
            }
      
            if (resumeField.value === '') {
              errorCount++;
              resumeField.parentNode.classList.add('error');
            }
      
            if (errorCount === 0) {
              console.log('sending mail...');
              form.classList.add('processing');
              
              sendMail();
            }
          });

          const sendMail = () => {
            const req = {
              body: {
                position: positionField.value,
                email: emailField.value,
                name: nameField.value,
                phone: phoneField.value,
                message: messageField.value,
                resume: resumeField.value,
              }
            };
            //We pass the api_key and domain to the wrapper, or it won't be able to identify + send emails
            var mailgun = new Mailgun({ apiKey: process.env.MAILGUN_KEY, domain: process.env.MAILGUN_DOMAIN });
            var d = new Date();
            var year = d.getFullYear();

            var recieverData = {
              //Specify email data
              from: `${req.body.name} <${req.body.email}>`,
              //The email to contact
              to: `${process.env.EMAIL_ADDRESS}`,
              //Subject and text data  
              subject: `Job Application [${req.body.position}]`,
              html: `
                <div style='background: #f3f3f3; width: 100%; max-width: 600px; border-radius: 20px; overflow: hidden; margin: auto; font-family: Gill Sans Extrabold, sans-serif, Arial;'>
                  <div style='text-align: center; padding: 20px; background: #fdc500;'>
                    <img src='http://${process.env.EMAIL_DOMAIN}/email-assets/logo.png' style='width: 240px; height: auto;'>
                  </div>
                  <div style='text-align: center; padding: 20px; background: #051833;'>
                    <h2 style='font-weight: bold; color: #ffffff; margin: 0;'>EC Calderon Construction Development, Inc.</h2>
                  </div>
                  <div style='padding: 20px;'>
                    <h1 style='margin: 0 0 20px 0;'>Job Application for <span style='color: #D90429;'>${req.body.position}</span></h1>
                    <div style='background: #ffffff; padding: 20px; position: relative;'>
                      <h4 style='margin: 0 0 10px 0;'>Job Position: ${req.body.position}</h4>
                      <h4 style='margin: 0 0 10px 0;'>Name: ${req.body.name}</h4>
                      <h4 style='margin: 0 0 10px 0;'>Email Address: ${req.body.email}</h4>
                      <h4 style='margin: 0 0 10px 0;'>Phone Number: ${req.body.phone}</h4>
                      <h4 style='margin: 0 0 10px 0;'>Resume Link: ${req.body.resume}</h4>
                      <div style='width: 100%; height: 1px; background: #ccc; margin: 20px 0;'></div>
                      <p style='margin: 0 0 10px 0; width:100%; white-space: pre-wrap; text-overflow: visible; word-wrap: break-word; line-height: 1.5;'>${req.body.message}</p>
                    </div>
                    <h3 style='margin: 10px 0 0 0; text-align: center;'>Note: You can reply directly to ${req.body.name}'s message.</h3>
                  </div>
                  <div style='width: 100%; height:1px; background-color:#d3d3d3; margin: 20px 0'></div>
                  <div style='padding: 20px;'>
                    <div style='color: #636363; margin-bottom: 20px;'><small>The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future. If you have any further questions, please tell us without hesitation: eccdi@eccalderon.com</small></div>
                    <div style='margin-bottom: 20px; color: #636363;'><small>Copyright &copy; ${year} EC Calderon Construction Development, Inc. All rights reserved.</small></div>
                    <table celpadding='10px' width='100%' border='0'>
                      <tr>
                        <td width='50%' valign='top'>
                          <div style='color: #636363;'>
                            <small>
                              <strong>EC Calderon Construction Development, Inc.</strong><br>
                              <strong>Main Office</strong><br>
                              P. Suico Tingub,<br>
                              Pagsabungan Road, Mandaue City,<br>
                              Cebu, Philippines 6000<br><br>
                              Tel +63 32 328 0912<br>
                              Fax +63 32 422 1055<br>
                            </small>
                          </div>
                        </td>
                        <td width='50%' valign='top'>
                          <div style='color: #636363; margin-bottom: 10px;'>
                            <div><small><strong>Connect with us</strong></small></div>
                            <a href='#facebook' style='margin: 0 5px;'><img src='http://${process.env.EMAIL_DOMAIN}/email-assets/icon_facebook.png'></a>
                            <a href='#twitter' style='margin: 0 5px;'><img src='http://${process.env.EMAIL_DOMAIN}/email-assets/icon_twitter.png'></a>
                            <a href='#linkedin' style='margin: 0 5px;'><img src='http://${process.env.EMAIL_DOMAIN}/email-assets/icon_linkedin.png'></a>
                            <a href='#instagram' style='margin: 0 5px;'><img src='http://${process.env.EMAIL_DOMAIN}/email-assets/icon_instagram.png'></a>
                          </div>
                          <a href='http://www.eccalderon.com' style='text-align: left;'>www.eccalderon.com</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              `
            };

            //Invokes the method to send emails given the above data with the helper library
            mailgun.messages().send(recieverData, function (err, body) {
              //If there is an error, render the error page
              if (err) {
                // res.status(500).send(err)
                console.log("got an error: ", err);
                form.classList.remove('processing');
              }
              //Else we can greet    and leave
              else {
                //Here "submitted.jade" is the view file for this landing page 
                //We pass the variable "email" from the url parameter in an object rendered by Jade
                // return res.status(200).send({ success: true })
                // console.log(body);
                // mailgun.messages().send(senderData, function (err, body) {
                //   if (err) {
                //     // res.status(500).send(err)
                //     console.log("got an error: ", err);
                //   }
                // });
                console.log("email sent...");
                form.classList.remove('processing');
                form.classList.add('success');
              }
            });
          };
        }
      });
    });
  }
};

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});