import Highway from '@dogstudio/highway';
import Quicklink, { listen, prefetch} from 'quicklink/dist/quicklink.mjs';
import 'intersection-observer';
import {
  setTabs,
  animateCss,
  initModals,
} from './utils';
import Fade from './transition';
import Renderer from './renderer';

document.addEventListener("DOMContentLoaded", (ev) => {
  const H = new Highway.Core({
    renderers: {
      home: Renderer,
      regular: Renderer,
      tabby: Renderer,
    },
    transitions: {
      default: Fade
    }
  });
  
  H.on('NAVIGATE_IN', ({ to }) => {
    // prefetch images
    let imgArr = [];
    [...to.view.querySelectorAll('*')].forEach(el => {
      const styles = window.getComputedStyle(el);
      if (styles.getPropertyValue('background-image') !== 'none') {
        const img = styles.getPropertyValue('background-image').split('"')[1];
        imgArr.push(img);
      }
      if (el.nodeName === 'IMG') {
        const img = el.src;
        imgArr.push(img);
      }
    });
    prefetch(imgArr, true);
  });

  // prefetch by quicklink
  let linkArr = [];
  [...document.querySelectorAll('*')].forEach(el => {
    const styles = window.getComputedStyle(el);
    if (styles.getPropertyValue('href') !== 'none') {
      const url = styles.getPropertyValue('href').split('"')[1];
      linkArr.push(url);
    }
  });
  prefetch(linkArr, true);

  const burgerBtn = document.querySelector('.burger');
  if (burgerBtn.getAttribute('listener') !== 'true') {
    burgerBtn.setAttribute('listener', 'true');
    burgerBtn.addEventListener('click', (e) => {
      e.stopImmediatePropagation();
      if (document.body.classList.contains('menu-open')) {
        document.body.classList.remove('menu-open');
      } else {
        document.body.classList.add('menu-open');
      }
    });
  }

  const scrollTop = document.querySelector('.scrollTop a');
  if (scrollTop.getAttribute('listener') !== 'true') {
    scrollTop.setAttribute('listener', 'true');
    scrollTop.addEventListener('mousedown', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.currentTarget.classList.add('hover');
      const player = e.currentTarget.querySelector('lottie-player');
      player.seek(0);
      player.play();
      setTimeout(() => {
        document.querySelector('.contentWrapper').scrollTo({ top: 0, behavior: 'smooth'});
      }, 800);
    });
  }
  
  // tabs
  setTabs();

  // modals
  initModals();

  // animations
  animateCss();
  
  // prefetch all links
  listen({
    el: document.body,
    priority: true
  });

  console.log('EC CALDERON WEBSITE....')
});